import Spinner from 'src/javascripts/components/utilities/Spinner';
import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import GridNav from 'src/javascripts/components/questionnaires/GridNav';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class QuestionnairesCollectionModulesGrid {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');
    const responseId = $('.polling-indicator').attr('data-response-id');

    // Functions used to navigate around the page
    GridNav();

    // Add border to navbar on scroll
    NavQuestionnaire();

    // Disable non-changed fields before submit, so only processing new data
    let removeNonChangedFields = function() {
      $('.data-collection-content input:not(:focus), .data-collection-content select:not(:focus), .data-collection-content textarea:not(:focus)').attr('disabled', true).addClass('temp');
      $('input.new-data[value=true]').each(function() {
        $(this).closest('tr').find('input, select, textarea').attr('disabled', false);
      })
    }

    // Enable non-changed fields; used after submit
    let enableNonChangedFields = function() {
      $('.data-collection-content input, .data-collection-content select, .data-collection-content textarea').attr('disabled', false).removeClass('temp');
    }  

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/imports/check_import_status.js?';

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message').length !== 0) { importPoll(); }

    // Timed save
    let timedSavePoll = (pollId) => {

      // Get polling indicator on page
      let savingPoll = $('.polling-indicator').attr("data-saving-poll");
      let status = $('.polling-indicator').attr('data-status');

      // Only continue if status indicator present (if should poll for recs is true)
      if ((savingPoll === pollId) && (status != "approved") && (status != "submitted")) {

        // Check if any values changed      
        let valChanged = $('.response-card-body[data-val-changed=\'true\']');

        // Remove non-changed fields
        removeNonChangedFields();

        // Submit form data
        let url = "/" + accountUrl + "/data-collection/responses/" + responseId + '/save';
        $.ajax({
          type: "PATCH",
          data: $("#save_response_form").serialize(),
          timeout: 4000,
          url: url,
          complete(result) { 
            setTimeout(function() { timedSavePoll(pollId) }, 4000);

            // Re-enable disabled fields
            enableNonChangedFields();

            // Set Api url to get data
            let accountUrl = $('body').attr('data-account-url');
            let params = {};
            let url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/poll.js?' + $.param(params);

            // Unhide polling spinner
            $('.polling-indicator').find('.spinner-border').removeClass('d-none');

            // Execute ajax request (using js erb template to render content so can control profile styling more easily)
            // Must specify '.js' otherwise processes as JSON
            $.ajax({
              type: "POST",
              timeout: 10000,
              url: url
            });

          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    let questionnaireStatus = $('.polling-indicator').attr('data-status');
    if ((questionnaireStatus !== 'approved') && (questionnaireStatus !== 'submitted')) {
      let rand = 1 + Math.floor(Math.random() * 1000000);
      rand = rand.toString();
      $('.polling-indicator').attr("data-saving-poll", rand);
      setTimeout(function() { timedSavePoll(rand) }, 10000);
    }

    // Custom methodology ratings
    if ( $('#customMethodologyRatingsList').length !== 0 ) {
      let companyId = $('#customMethodologyRatingsList').attr('data-company-id');
      let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/custom_methodology_scorecard";
      let params = {};
      Spinner($('#customMethodologyRatingsList'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + $.param(params)
      });
    }


  }

}