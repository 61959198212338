import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import Swal from 'sweetalert2';
import 'summernote/dist/summernote-bs4.min.js'
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class ClientsEmailsNew {

  constructor() {}

  render() {

    const accountUrl = $('body').attr('data-account-url');
    const clientId = $('#etemp').attr('data-client-id');

    $('#sendEmailInvite').click(function() {
      return Swal.fire({
        title: "Please confirm",
        text: "Just checking: ready to send this email now?",
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        if (result.value) {
          $.ajaxSetup({
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
          });          
          $('#sendEmailInviteForm').submit();
        }
      });      
    })

    $('.copy-assessment-invitation').click(function() {

      /* Get the text field */
      let copyText = document.querySelector('#assessment_invitation_token');
      
      /* Select the text field */  
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      // Update tooltiop
      $(this).attr('data-title', "Copied!");
      $(this).attr('data-original-title', "Copied!");
      $(this).tooltip('hide');
      $(this).tooltip('show');

    })

    // Render table
    $('a[href="#emailTemplatesTab"]').on('shown.bs.tab', function (e) {
      if ( $('#client_e_templates_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#client_e_templates_table'));
        $.when( table.render() ).then( status => table.setUp('client_e_templates_table') )
      }
    })
    if ($('#emailTemplatesTab').hasClass('active')) {
      if ( $('#client_e_templates_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#client_e_templates_table'));
        $.when( table.render() ).then( status => table.setUp('client_e_templates_table') )
      }
    }

    // Render table
    $('a[href="#emailsSentTab"]').on('shown.bs.tab', function (e) {
      if (( $('#client_e_table_wrapper').length === 0 ) && ($('#client_e_table').length !== 0)) {
        let table = new DefaultTable($('#client_e_table'));
        $.when( table.render() ).then( status => table.setUp('client_e_table') )
      }
    })
    if ($('#emailsSentTab').hasClass('active')) {
      if (( $('#client_e_table_wrapper').length === 0 ) && ($('#client_e_table').length !== 0)) {
        let table = new DefaultTable($('#client_e_table'));
        $.when( table.render() ).then( status => table.setUp('client_e_table') )
      }      
    }

    const bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/' + accountUrl + '/clients/email_templates/search?query=%QUERY',
        wildcard: "%QUERY"
      }});

    bloodhound.initialize();

    let emptyTemplate = '<div class="empty-message">Unable to find that</div>';

    let emailTemplate = (name) => 
      '<div>' + name + '</div>';

    $('#etemp').typeahead({
      hint: true,
      highlight: true,
      minLength: 0
    }, {
      name: 'typeahead',
      display: 'name',
      limit: 44,
      source: bloodhound.ttAdapter(),
      templates: {
        empty: emptyTemplate,
        suggestion(el) {
          return emailTemplate(el.name);
        }
      }
    });

    $('#etemp').bind('typeahead:beforeselect', function(ev, suggestion) { 
      $('.modal-body').css('opacity', 0.2);
      Spinner($('.modal-content'));
      let url = '/' + accountUrl + '/clients/' + clientId + '/emails/new?tab=emailTab&template_id=' + suggestion.value;
      Turbo.visit(url, { action: 'replace' });
    });

  }

}