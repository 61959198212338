import Spinner from 'src/javascripts/components/utilities/Spinner';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import ManualListTypeahead from 'src/javascripts/components/typeaheads/ManualListTypeahead';
import ManualListTypeaheadMultiple from 'src/javascripts/components/typeaheads/ManualListTypeaheadMultiple';
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Swal from 'sweetalert2';
import {filterTypeahead, filterTypeaheadNoCache} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function(parentContainerId = null) {
  const accountUrl = $('body').attr('data-account-url');
  const responseId = $('.polling-indicator').attr('data-response-id');
  let parentContainerEl;
  // Set parent container to scope code to
  if (parentContainerId === null) {
    parentContainerEl = 'body';
  } else {
    parentContainerEl = '#' + parentContainerId;
  }
  let parentContainer = $(parentContainerEl);

  // Format display numbers in form
  NumberDisplay();

  // Disable non-changed fields before submit, so only processing new data
  let removeNonChangedFields = function() {
    $('.data-collection-content input, .data-collection-content select, .data-collection-content textarea').attr('disabled', true).addClass('temp');
    $('input.new-data[value=true]').each(function() {
      $(this).closest('tr').find('input, select, textarea').attr('disabled', false);
      $(this).closest('.form-group').find('input, select, textarea').attr('disabled', false);
    })
  }

  // Enable non-changed fields; used after submit
  let enableNonChangedFields = function() {
    $('.data-collection-content input, .data-collection-content select, .data-collection-content textarea').attr('disabled', false).removeClass('temp');
    $('.response-card-body[data-val-changed=\'true\']').attr('data-val-changed', 'false');
  }  

  // Toggle new data indicator
  let toggleNewData = function(el) {
    let row = el.closest('.question-row');
    let cardBody = el.closest('.response-card-body');
    let userId = cardBody.attr('data-user-id');
    let len = el.val.length;
    if (len > 0) {
      cardBody.attr('data-val-changed', 'true');
      cardBody.find('.user-id').attr('value', userId);
      cardBody.find('.new-data').attr('value', true);
      row.find('.new-data').attr('value', true);
    } else {
      cardBody.attr('data-val-changed', 'false');
      cardBody.find('.user-id').attr('value', '');
      cardBody.find('.new-data').attr('value', true);
      row.find('.new-data').attr('value', false);
    }
  }

  // Set updated at/by if present
  let setUpdatedAtCols = function(el) {
    let dataProvidedDateInput = el.closest('tr').find('.data-provided-date');
    if (typeof dataProvidedDateInput !== 'undefined') {
      let dataProvidedDateVal = dataProvidedDateInput.attr('data-current-date');
      dataProvidedDateInput.val(dataProvidedDateVal);
    }
    let dataProvidedByInput = el.closest('tr').find('.data-provided-by');
    if (typeof dataProvidedByInput !== 'undefined') {
      let dataProvidedByVal = dataProvidedByInput.attr('data-current-user');
      dataProvidedByInput.val(dataProvidedByVal);
    }
  }

  // Set dependent dropdown values 
  let setDependentDropdownValues = function(el) {

    // Set vars
    let answer = el.val();
    let dependentCols = el.closest('td').attr('data-dependent-column-ids');

    // Remove green bg and return if removing the answer or no answer available
    if (answer === '') { 
      el.closest('.response-table-cell').removeClass('completed');
      return;
    }

    // Return if no dependent columns
    if ((typeof dependentCols === 'undefined') || (JSON.parse(dependentCols).length === 0)) {return;}

    // Iterate through any dependent columns
    dependentCols = JSON.parse(dependentCols);
    $.each(dependentCols, function(i, colId) {

      // Set dependent cell
      let dependentCell = el.closest('tr').find('td[data-column-id=' + colId + ']');
      if (typeof dependentCell === 'undefined') {return;}

      // Set the associated select (dropdown) and options
      let select = dependentCell.find('select');
      let cellOpts = select.attr('data-cell-options');
      if (typeof cellOpts === 'undefined') {return;}
      cellOpts = JSON.parse(cellOpts);

      // Set the dropdown options that should be set to visible
      let visible = []
      $.each(cellOpts[answer], function(i, o) { visible.push(o[0]); })

      // Remove select value if not in visible
      let selectedVal = select.val();
      if (jQuery.inArray(selectedVal, visible) === -1) { 
        select.val("").change(); 
        select.closest('.response-table-cell').removeClass('completed');
      }

      // Adjust display (visibility) of dropdown options
      select.find('option').each(function(i, o) {
        if (jQuery.inArray(o.value, visible) !== -1) {
          o.style.display = 'block';
        } else if (o.value === "") {
          o.style.display = 'block';
        } else {
          o.style.display = 'none';
        }
      })

    })

  }

  // Apply a row to all others
  parentContainer.find('.apply-row-to-all').click(function() {
    $(this).closest('tr').find('td').each(function(index) {
      let input = $(this).find('input.answer-text');
      let select = $(this).find('select.answer-text');
      if (input.length !== 0) {
        let val = input.val();
        $(this).closest('tbody').find('tr').each(function() {
          let relatedTd = $(this).find('td')[index];
          let input = $(relatedTd).find('input.answer-text')
          input.val(val);
          $(relatedTd).find('.twitter-typeahead input').val(val);
          toggleNewData(input);
          setUpdatedAtCols(input);
        })
      } else if (select.length !== 0) {
        let val = select.val();
        $(this).closest('tbody').find('tr').each(function() {
          let relatedTd = $(this).find('td')[index];
          let select = $(relatedTd).find('select.answer-text')
          select.val(val).trigger('change');
          $(relatedTd).find('.twitter-typeahead input').val(val);
          toggleNewData(select);
          setUpdatedAtCols(select);
        })
      }
    });
  });

  // Manual list typeaheads
  if (parentContainer.find('.manual-list-typeahead').length !== 0) {
    parentContainer.find('.manual-list-typeahead').typeahead('destroy');
    parentContainer.find('.manual-list-typeahead').each(function(el){
      let id = $(this).attr('id');
      let t = new ManualListTypeahead(el);
      t.render(id)
    });
  }

  // Manual list typeaheads - multiple selection allowed
  if (parentContainer.find('.manual-list-typeahead-multiple').length !== 0) {
    parentContainer.find('.manual-list-typeahead-multiple').typeahead('destroy');
    parentContainer.find('.manual-list-typeahead-multiple').each(function(el){
      let id = $(this).attr('id');
      let t = new ManualListTypeaheadMultiple(el);
      t.render(id)
    });
  }

  // On select a typeahead
  parentContainer.find('.typeahead').click(function() {
    let ig = $(this).closest('td');
    if (ig.length === 0) {ig = $(this).closest('.input-group')}
    let hidden = ig.find('input.answer-text');
    let tp = ig.find('.typeahead');
    hidden.val('');
    tp.css('background', '').removeClass('no-asc no-desc yes-asc yes-desc information-not-available filled');
    $(this).typeahead('val', '');
    $(this).focus();
  });

  // On update text field or textarea
  parentContainer.find('input, textarea').blur(function() {
    let val = $(this).val();
    let answerText = $(this).closest('.input-group').find('.answer-text').val();
    $(this).css('background', '').removeClass('no-asc no-desc yes-asc yes-desc information-not-available filled');
    let metricDir = $(this).attr('data-metric-direction');
    let cell = $(this).closest('.response-table-cell');
    if (typeof metricDir === 'undefined') {metricDir = ''};
    if ((val === "Yes") && (metricDir !== '')) {
      $(this).addClass('yes-' + metricDir);
    } else if ((val === "No") && (metricDir !== '')) {
      $(this).addClass('no-' + metricDir);
    } else if ((val === "High") && (metricDir !== '')) {
      $(this).addClass('high-' + metricDir);
    } else if ((val === "Low") && (metricDir !== '')) {
      $(this).addClass('low-' + metricDir);
    } else if ((val === "Medium") && (metricDir !== '')) {
      $(this).addClass('medium');
    } else if (val === "Information not available") {
      $(this).addClass('information-not-available');
    } else if (val.length > 0) {
      $(this).addClass('filled');
    } else if ((typeof answerText !== 'undefined') && (answerText.length > 0)) {
      $(this).addClass('filled');
    }
    if (typeof cell !== 'undefined') {
      cell.addClass('completed');
    }
    if ((typeof answerText !== 'undefined') && (answerText.length > 0)) {
      $(this).closest('.question-row').removeClass('incomplete');
    }
  });

  // On edit long text modal
  parentContainer.find('.long-text-modal').on('hidden.bs.modal', function(e) {
    let val = $(this).find('textarea').val();
    if (val.length > 0) {
      $(this).closest('td').find('.add-long-text-modal').addClass('bg-a-rating').empty().append("Edit...")
    } else {
      $(this).closest('td').find('.add-long-text-modal').removeClass('bg-a-rating').empty().append("Add...");
    }
  })

  // Indicate value changed on update
  parentContainer.find('input, textarea').keyup(function() {
    let el = $(this);
    toggleNewData(el);
  });

  // Indicate value changed on update
  parentContainer.find('select:not(.cell-dropdown-option)').change(function() {
    let el = $(this);
    toggleNewData(el);
  });

  // Update dependent dropdowns on select
  parentContainer.find('select.cell-dropdown-option').change(function() {
    let el = $(this);
    toggleNewData(el);

    // Set dependent cell dropdowns
    setDependentDropdownValues(el);

    // Update associated updated at/by cols if present
    setUpdatedAtCols(el);

  });

  // If companies search included
  if (parentContainer.find('#companies_search').length !== 0) {
    let el = $('#companies_search')
    let token = el.data('survey-token');
    filterTypeahead('#companies_search', 'companies', '/' + accountUrl + '/companies/search?survey_token=' + token, 382, 'logo');
    toggleNewData(el);
    el.bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      el.closest('.input-group').find('.answer-text').val(suggestion.name);
    })
  }

  // Update associated updated at/by cols if present
  parentContainer.find('td input, td textarea').keyup(function() {
    let el = $(this);
    setUpdatedAtCols(el);
  });

  // Save on blur of field with conditions
  parentContainer.find('.input-group[data-has-dependencies=\'true\'').find('input:not(.typeahead), textarea').blur(function() {
    let valsChanged = $('.response-card-body[data-val-changed=true]');
    if (valsChanged.length > 0) {
      $('#submitResponseSpinnerContainer').removeClass('d-none');
      Spinner($('#submitResponseSpinnerContainer'));
      let openSections = [];
      $('.table.card-list.active').each(function() {
        openSections.push($(this).attr('data-included-section-id'));
      })
      $('form').append("<input type='hidden' name='open_sections' value='" + openSections + "' autocomplete='off'>");
      $('#hiddenSaveResponse').click();
    }
  });

  // Save on blur of field with conditions
  parentContainer.find('.input-group[data-has-dependencies=\'true\'').find('select').change(function() {
    let valsChanged = $('.response-card-body[data-val-changed=true]');
    if (valsChanged.length > 0) {
      $('#submitResponseSpinnerContainer').removeClass('d-none');
      Spinner($('#submitResponseSpinnerContainer'));
      let openSections = [];
      $('.table.card-list.active').each(function() {
        openSections.push($(this).attr('data-included-section-id'));
      })
      $('form').append("<input type='hidden' name='open_sections' value='" + openSections + "' autocomplete='off'>");
      $('#hiddenSaveResponse').click();
    }
  });


  let removeConditions = function(inputGroup, suggestion = null) {
    let conditions = inputGroup.attr('data-determining-conditions');
    if (typeof conditions === 'undefined') {return}
    conditions = JSON.parse(conditions);
    if (suggestion !== null) {
      $.each(conditions, function(i,arr) {
        conditions[i].push(suggestion);
      })
    }

    // Add conditions from others tied to same included question
    $('.input-group').filter(function() {
      let c = $(this).attr('data-determining-conditions');
      if (typeof c === 'undefined') {
        return false
      } else {
        return c.includes('[' + conditions[0][0] + ',');
      }
    }).each(function() {
      let ic = $(this).attr('data-determining-conditions');
      let val = $(this).find('.answer-text.tt-input').val();
      ic = JSON.parse(ic);
      ic[0].push(val);
      conditions.push(ic[0]);
    })

    if (typeof conditions !== 'undefined') {
      let matchesCriteria = {};
      $.each(conditions, function(i, arr) {
        matchesCriteria[arr[0]] = 0;
      });
      $.each(conditions, function(i, arr) {
        let dependentQuestion = arr[0];
        let dependentTable = arr[1];
        let displayVal = arr[2];
        let selectedVal = arr[3];
        if ((typeof selectedVal !== 'undefined') && (selectedVal.length > 0) && (displayVal.indexOf(selectedVal) !== -1)) {
          matchesCriteria[dependentQuestion]++;
        }
        if (typeof dependentQuestion !== 'undefined') {
          let dependentRow = $('tr.question-row[data-included-question-id=' + dependentQuestion + ']');
          if (matchesCriteria[dependentQuestion] > 0) {
            dependentRow.removeClass('d-none');
          } else {
            dependentRow.addClass('d-none');
          }
        }
        if (typeof dependentTable !== 'undefined') {
          let dependentCard = $('div[data-included-table-id=' + dependentTable + ']');
          let dependentRow = dependentCard.closest('tr.question-row');
          if (matchesCriteria[dependentQuestion] > 0) {
            dependentCard.removeClass('d-none');
            dependentRow.removeClass('d-none');
          } else {
            dependentCard.addClass('d-none');
            dependentRow.addClass('d-none');
          }

        }
      })
    }
  }

  // Save if conditions
  parentContainer.find('.manual-list-typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let el = $(this);
    let ig = el.closest('.input-group');
    let cell = el.closest('.response-table-cell');
    let metricDir = $(this).attr('data-metric-direction');
    if (typeof metricDir === 'undefined') {metricDir = ''}
    toggleNewData(el);

    // Set class
    if ((suggestion === "Yes") && (metricDir !== '')) {
      el.addClass('yes-' + metricDir);
    } else if ((suggestion === "No") && (metricDir !== '')) {
      el.addClass('no-' + metricDir);
    } else if (suggestion === "Information not available") {
      el.addClass('information-not-available');
    } else {
      el.addClass('filled');
    }
    if (typeof cell !== 'undefined') {
      cell.addClass('completed');
    }
    el.closest('.question-row').removeClass('incomplete');

    let valsChanged = $('.response-card-body[data-val-changed=true]');
    let hasDependencies = ig.attr('data-has-dependencies');
    if ((valsChanged.length > 0) && (hasDependencies === "true")) {
      removeConditions(ig, suggestion);
    }

  });

  // Check for matching list for manual typeaheads
  $('.manual-list-typeahead').blur(function() {
    let list = $(this).attr('data-list-options');
    let val = $(this).val();
    if ((val.length > 0) && (!JSON.parse(list).includes(val))) {
      ToastCustom('Not included in list', 'Please select an option from the list')
      $(this).val('');
      $(this).removeClass('filled');
      $(this).typeahead('destroy');
      let id = $(this).attr('id');
      let t = new ManualListTypeahead($(this));
      t.render(id);
      $(this).focus();
    }
  })

  // Check numeric values
  $('.decimal-field').on('input', function() {
    let value = $(this).val();
    let decimalCount = (value.split('.').length - 1);
    // Check if the input is numeric or decimal
    if (!$.isNumeric(value) || decimalCount > 1) {
      // If not, remove the last character
      $(this).val(value.slice(0, -1));
    }
  })
  $('.percent-field, .integer-field').on('input', function() {
    let inputValue = $(this).val();
    let numericValue = inputValue.replace(/[^0-9]/g, "");
    $(this).val(numericValue);
  })
  $('.percent-field').on('input', function() {
    let val = $(this).val();
    let intVal = parseInt(val);
    if ((val.length > 0) && (intVal > 100)) {
      $(this).val('');
      ToastCustom('Value too large', 'Value must be less than or equal to 100');      
      $(this).removeClass('filled');
      $(this).focus();
    } else if ((val.length > 0) && (intVal < 0)) {
      $(this).val('');
      ToastCustom('Value too small', 'Value must be greater than or equal to 0');
      $(this).removeClass('filled');
      $(this).focus();
    }
  })

  // Update dependent dropdowns on page load
  parentContainer.find('select.cell-dropdown-option').each(function() {
    setDependentDropdownValues($(this));      
  })

  // Save on blur of inputs
  parentContainer.find('.response-table-cell[data-has-totals=\'true\'').find('input, textarea').blur(function() {
    let valsChanged = $(this).closest('.response-card-body[data-val-changed=true]');
    if (valsChanged.length > 0) {
      $('#submitResponseSpinnerContainer').removeClass('d-none');
      Spinner($('#submitResponseSpinnerContainer'));
      $('#hiddenSaveResponse').click();
    }
  });
  parentContainer.find('.response-table-cell[data-has-totals=\'true\'').find('select').change(function() {
    let valsChanged = $(this).closest('.response-card-body[data-val-changed=true]');
    if (valsChanged.length > 0) {
      $('#submitResponseSpinnerContainer').removeClass('d-none');
      Spinner($('#submitResponseSpinnerContainer'));
      $('#hiddenSaveResponse').click();
    }
  });

  // Disable inputs if submitted
  let submitted = $('#hidden_submit_questionnaire').val();
  let submittedEditable = $('#hidden_submit_questionnaire').attr("data-submitted-editable");
  let canManageQuestionnaires = $('#hidden_submit_questionnaire').attr("data-can-manage-questionnaires");
  let status = $('.polling-indicator').attr('data-status');
  if ((canManageQuestionnaires === "false") || ((submitted === 'true') && (submittedEditable == 'false'))) {
    $('input, select, textarea').attr('disabled', true);
    $('.mark-not-applicable').removeClass('mark-not-applicable');
    $('.prefer-not-disclose').removeClass('prefer-not-disclose');
    let sumCont = $('.summernote');
    $.each(sumCont, function() {
      $(this).summernote('disable');
    })
  }

  // On show of pre-fill modal, hide tooltip
  parentContainer.find('.prefill-modal').on('shown.bs.modal', function() {
    $('.tooltip').tooltip('hide');
    $(this).find('textarea').on('focus', function() {
      $('.tooltip').tooltip('hide');
    })
  })
  parentContainer.find('.prefill-modal').on('hidden.bs.modal', function() {
    let val = $(this).find('.answer-commentary').val();
    let iga = $(this).closest('.input-group-append')
    let icon = iga.find('.pre-fill-answer')
    if (val.length > 0) {
      iga.attr('data-original-title', 'Commentary added to this answer');
      icon.addClass('bg-gray200');
    } else {
      iga.attr('data-original-title', 'Add commentary to this answer');
      icon.removeClass('bg-gray200');
    }
  })  

  // Copy previous data
  parentContainer.find('.copy-previous-answer').click(function() {
    let previousResponseId = $(this).attr('data-previous-response-id');
    let answerId = $(this).attr('data-answer-id');
    let params = {};
    params['previous_response_id'] = previousResponseId;
    params['answer_id'] = answerId;
    let url = "/" + accountUrl + "/data-collection/responses/" + responseId + "/answers/prefill?" + $.param(params);

    // Opacity on page
    $('.modal.prefill-modal').modal('hide');
    $('#submitResponseSpinnerContainer').removeClass('d-none');
    Spinner($('#submitResponseSpinnerContainer'));

    // Indicate should update included section
    let cardBody = $(this).closest('.card').find('.response-card-body');
    let userId = cardBody.attr('data-user-id');
    cardBody.attr('data-val-changed', 'true');
    cardBody.find('.user-id').attr('value', userId);
    cardBody.find('.new-data').attr('value', true);

    // Submit
    $.ajax({
      type: "POST",
      dataType: "script",
      timeout: 3000,
      url: url
    })
  })

  // Mark not applicable
  parentContainer.find('.mark-not-applicable').click(function() {
    let el = $(this);
    let disabled = $(this).attr('disabled');
    if (disabled === "disabled") {return;}
    toggleNewData(el);
    if (el.attr('data-selected') === 'true') {
      el.closest('.input-group').find('.answer-text').val('').removeClass('filled').change();
      el.removeClass('bg-gray100').addClass('bg-white').attr('data-selected', 'false');
      el.attr('data-original-title', 'Click to mark this question as not applicable. Please provide explanation in the comment if necessary.')
    } else {
      el.closest('.input-group').find('.answer-text').val('Not applicable').addClass('filled').change();
      el.closest('.question-row').removeClass('incomplete');
      el.removeClass('bg-white').addClass('bg-gray100').attr('data-selected', 'true');
      el.attr('data-original-title', 'Click to remove the \'Not applicable\' designation.')
    }
    el.tooltip('hide');
  });

  // Prefer not disclose
  parentContainer.find('.prefer-not-disclose').click(function() {
    let el = $(this);
    let disabled = $(this).attr('disabled');
    if (disabled === "disabled") {return;}
    toggleNewData(el);
    if (el.attr('data-selected') === 'true') {
      el.closest('.input-group').find('.answer-text').val('').removeClass('filled').change();
      el.removeClass('bg-gray100').addClass('bg-white').attr('data-selected', 'false');
      el.attr('data-original-title', 'Click to mark \'Prefer not to disclose\'. Please provide explanation in the comment if necessary.')
    } else {
      el.closest('.input-group').find('.answer-text').val('Prefer not to disclose').addClass('filled').change();
      el.closest('.question-row').removeClass('incomplete');
      el.removeClass('bg-white').addClass('bg-gray100').attr('data-selected', 'true');
      el.attr('data-original-title', 'Click to remove the \'Prefer not to disclose\' designation.')
    }
    el.tooltip('hide');
  });

  // Filter typeaheads for documents
  let answerId = parentContainer.find('.documents-typeahead').attr('data-answer-id');
  let searchUrl = "/" + accountUrl + "/data-collection/responses/" + responseId + "/documents/search?"
  filterTypeaheadNoCache(parentContainerEl + ' .documents-typeahead', 'documents', searchUrl, 143, 'generic');
  parentContainer.find('.documents-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    let filePicker = $(this).closest('.supporting-documentation').find('.response-document-filepicker');
    let answerId = filePicker.attr('data-answer-id');
    let responseDocumentId = suggestion.value;

    // Only continue if not already added
    let exists = $(this).closest('.response-card-body').find('.response-document-row[data-document-id=\'' + responseDocumentId + '\']');
    if (exists.length !== 0) {
      ToastCustom('Already attached', 'You have already attached that document to this response');

      // Blur input 
      $(this).typeahead("val", "");
      $(this).blur();

    } else {

      // Set card body to indicate value changed for reloading page
      let cardBody = $(this).closest('.card-body.response-card-body');
      cardBody.attr('data-val-changed', 'true');
      toggleNewData($(this));

      // Set spinner so user knows in progress
      cardBody.addClass('opaque');
      Spinner($(this).closest('.card'));

      // Upload file
      let url = "/" + accountUrl + '/data-collection/responses/' + responseId + '/answer_documents';
      let formData = {}
      formData['answer_id'] = answerId;
      formData['response_document_id'] = responseDocumentId;

      // Update local attribute
      return $.ajax({
        type: 'POST',
        url: url,
        dataType: "script",
        data: formData
      });
    }
  });

  // Set a source link
  parentContainer.find('.supporting-links').on('blur paste', function(i, elem) {
    let el = $(this);
    setTimeout(function() {
      let link = el.val();
      if (link.length > 3) {
        let accountUrl = $('body').attr("data-account-url");
        let answerId = el.attr('data-answer-id');

        // Set card body to indicate value changed for reloading page
        let cardBody = el.closest('.card-body.response-card-body');
        cardBody.attr('data-val-changed', 'true');

        // Set spinner so user knows in progress
        cardBody.addClass('opaque');
        Spinner(el.closest('.card'));

        // Blur
        el.val('').blur();

        // Upload file
        let url = "/" + accountUrl + '/data-collection/responses/' + responseId + '/documents';
        let formData = {}
        formData['file'] = {}
        formData['file_type'] = "link";
        formData['file']['key'] = link;
        formData['answer_id'] = answerId;

        // Set associated input field as filled
        el.closest('.question-row').find('.answer-text').addClass('filled');
        el.closest('.question-row').removeClass('incomplete');
        toggleNewData(el);

        // Update local attribute
        $.ajax({
          type: 'POST',
          url: url,
          dataType: "script",
          data: formData,
        });
      }
    }, 0);
  });

  // Blur on select
  parentContainer.find('.documents-typeahead').bind('typeahead:select', function(ev, suggestion) {
    $(this).typeahead("val", "");
    $(this).blur();
  });

  // On initial click of submit, check any validations
  $('#checkValidations').off().on('click', function(el) {

    // Indicate that checking validations
    $('#confirmSubmitHeader').empty().append('Checking validations');
    $('#confirmMessage').addClass('d-none');
    $('#validationErrorMessages').addClass('d-none').empty();
    $('#checkingValidationsMessage').removeClass('d-none');
    $('#approvalMessageModal .modal-footer').addClass('d-none');
    Spinner('#confirmSubmitBody');

    // Check validations
    let url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/check_validations';
    $.ajax({
      type: "POST",
      dataType: "application/json",
      timeout: 3000,
      url: url,
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        let errorAnswers = responseText.error_answers;
        let alwaysMandatoryAnswers = responseText.always_mandatory_answers;
        let incompleteAnswers = responseText.incomplete_required_answers;
        let allowSubmitOverride = responseText.allow_submit_override;
        let noIssues = (errorAnswers.length === 0) && ((incompleteAnswers === null) || (incompleteAnswers.length === 0));
        if (noIssues) {
          $('#confirmSubmitHeader').empty().append('Confirm submission');
          $('#checkingValidationsMessage').addClass('d-none');
          $('#confirmMessage').removeClass('d-none');
          $('#approvalMessageModal .modal-footer').removeClass('d-none');
          $('#approvalMessageModal .modal-footer').find('#submitQuestionnaireResponse').empty().append('Submit');
          $('#confirmSubmitBody').find('.spinner-container').remove();

        } else {

          // Always mandatory answers
          let issues = '<div class=\'h6 mb-0\'>';
          if (alwaysMandatoryAnswers.length > 0) {
            $.each(alwaysMandatoryAnswers, function(i,err) {
              issues += '<div class="border-dashed-bottom-dark pb-3 mb-3">';
              issues += '<div class="font-italic mb-2"><span class=\'font-weight-bold\'>Question: </span>' + err['question_text'] + '</div>';
              issues += '<div class="mb-2">' + err['error_message'] + '</div>';
              issues += '<div class="font-weight-bold text-danger">Note that answering this question is mandatory.</div>';
              issues += '</div>';
            })
          }

          // Errors
          issues += '<div class=\'h6 mb-0\'>';
          if (errorAnswers.length > 0) {
            $.each(errorAnswers, function(i,err) {
              issues += '<div class="border-dashed-bottom-dark pb-3 mb-3">';
              issues += '<div class="font-italic mb-2"><span class=\'font-weight-bold\'>Question: </span>' + err['question_text'] + '</div>';
              issues += '<div>' + err['error_message'] + '</div>';
              issues += '</div>';
            })
          }

          // Incomplete required answers
          if ((incompleteAnswers !== null) && (incompleteAnswers.length > 0)) {
            let submissionAttempts = $('#save_response_form').data('submission-attempts');
            issues += '<div class="border-dashed-bottom-dark pb-3 mb-3">';
            issues += 'We found ' + incompleteAnswers.length + ' incomplete required questions.<br><br>';
            issues += 'See incomplete questions highlighted in red.<br>'
            if ((allowSubmitOverride) && (errorAnswers.length === 0)) {
              issues += '<br>You may over-ride these issues and submit the survey below, but please complete the remaining fields to the extent possible.<br><br>';
            }
            issues += '</div>';
          }
          issues += '<div class="font-italic">Please correct these issues and try again, or reach out to us for support at support@ethosesg.com';
          issues += '</div>';

          // Update modal
          $('#confirmSubmitHeader').empty().append('Please correct the following issues');
          $('#checkingValidationsMessage').addClass('d-none');
          $('#confirmMessage').addClass('d-none');
          $('#validationErrorMessages').removeClass('d-none').append(issues);
          $('#confirmSubmitBody').find('.spinner-container').remove();

          if ((allowSubmitOverride) && (alwaysMandatoryAnswers.length === 0)) {
            $('#approvalMessageModal .modal-footer').removeClass('d-none');
            $('#approvalMessageModal .modal-footer').find('#submitQuestionnaireResponse').empty().append('Override and submit');
          }

        }
      }
    });

    // On close of modal
    $('#approvalMessageModal').on('hidden.bs.modal', function (e) {
      Turbo.visit(window.location.href);
    })

  })

  // On submit
  $('#submitQuestionnaireResponse').click(function(el) {
    el.preventDefault();

    // Add field to form to indicate we're submitting it
    $('#hidden_submit_questionnaire').val("true");

    // Opacity on page
    $('#approvalMessageModal').modal('hide');
    // $('#save_response_form .data-collection-content').css('opacity', 0.25)
    $('#submitResponseSpinnerContainer').removeClass('d-none');
    Spinner($('#submitResponseSpinnerContainer'));

    // Remove non-changed fields
    removeNonChangedFields();

    // Submit form
    $('#hiddenSaveResponse').click();

    // Change fields back to enabled
    enableNonChangedFields();

  })

  // On submit
  $('.unsubmit-questionnaire-response').click(function(el) {
    el.preventDefault();

    // Confirm and then continue
    return Swal.fire({
      title: "Confirm",
      text: "Please confirm that you would like to change the status of this questionnaire back to 'draft'.",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        
        // Add field to form to indicate we're submitting it
        $('#hidden_submit_questionnaire').val("false");

        // Opacity on page
        $('#save_response_form .data-collection-content').css('opacity', 0.25)
        $('#submitResponseSpinnerContainer').removeClass('d-none');
        Spinner($('#submitResponseSpinnerContainer'));

        // Remove non-changed fields
        removeNonChangedFields();

        // Submit form - first need to enable fields, otherwise rails UJS doesn't submit form
        $('input, select, textarea').attr('disabled', false);
        $('#hiddenSaveResponse').click();

		    // Change fields back to enabled
		    enableNonChangedFields();

      }
    });

  })

  // On save
  $('#saveQuestionnaireResponse').click(function(el) {
    el.preventDefault();

    // Check if any values changed      
    let valChanged = $('.response-card-body[data-val-changed=\'true\']');

    // Opacity on page
    $('#submitResponseSpinnerContainer').removeClass('d-none');
    Spinner($('#submitResponseSpinnerContainer'));

    // Spinner
    $('.polling-indicator').find('.spinner-border').removeClass('d-none');

    // Remove non-changed fields
    removeNonChangedFields();

    // Submit form
    $('#hiddenSaveResponse').click();

    // Change fields back to enabled
    enableNonChangedFields();    

  })

  // Update hidden fields on type of value/number text field
  parentContainer.find(".value-text").on("change paste keyup", function() {

    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;

    // Update related numeric input (remove comma, return to value)
    let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
    let numericVal = parseFloat( $(this).val() );

    // Divide by 100 if percentage
    if ($(this).hasClass('number-percentage')) {
      numericVal = numericVal / 100;
    }

    // Update associated hidden field
    if (Number.isNaN(numericVal)) {
      numericInput.val('');
    } else {
      numericInput.val(numericVal);
    }

  });

  // Update hidden fields on type of value/number text field
  let updateDynamicAnswer = function(container) {
    let i = 0;
    let len = container.find('.dynamic-row').length;
    let answerText = '';
    let answerField = container.closest('.response-card-body').find('.answer-text');
    while (i < len) {
      let val = container.find('.dynamic-row').eq(i).val();
      answerText += val;
      answerText += '|';
      i++;
    }
    answerField.val(answerText);
    toggleNewData(container);
  }

  let removeDynamicRow = function(el) {
    el.remove();
    $('.tooltip').tooltip('hide');
  }

  // Update dynamic answer on blur
  parentContainer.find(".dynamic-row").on("blur", function() {
    let container = $(this).closest('.response-card-body').find('.dynamic-row-container');
    updateDynamicAnswer(container);
  });

  let dynamicRowTemplate = "<div class=\'input-group mb-1\'>";
  dynamicRowTemplate += "<input type=\'text\' name=\'row\' autocomplete=\'off\' placeholder=\'Enter response here\' class=\'form-control filled dynamic-row\'>";
  dynamicRowTemplate += "<div class=\'input-group-append\''>";
  dynamicRowTemplate += "<a class=\'input-group-text border-dark remove-dynamic-row clickable bg-white\' data-title=\'Remove this data\' data-toggle=\'tooltip\'>";
  dynamicRowTemplate += "<i class=\'fad fa-trash\'></i>"
  dynamicRowTemplate += "</a></div></div>";

  parentContainer.find(".add-dynamic-row").on("click", function() {
    let container = $(this).closest('.response-card-body').find('.dynamic-row-container');
    updateDynamicAnswer(container);
    let lastRow = container.find('.dynamic-row.last');
    let lastRowVal = container.find('.dynamic-row.last').val();
    let newIg = $(dynamicRowTemplate);
    newIg.find('.dynamic-row').val(lastRowVal);
    lastRow.before(newIg);
    container.find('.dynamic-row.last').val('').removeClass('filled');

    newIg.find('.remove-dynamic-row').click(function() {
      let container = $(this).closest('.response-card-body').find('.dynamic-row-container');
      let ig = $(this).closest('.input-group');
      $.when(removeDynamicRow(ig)).then(data => updateDynamicAnswer(container))
    })

  });

  parentContainer.find('.remove-dynamic-row').click(function() {
    let container = $(this).closest('.response-card-body').find('.dynamic-row-container');
    let ig = $(this).closest('.input-group');
    $.when(removeDynamicRow(ig)).then(data => updateDynamicAnswer(container))
  })

  // Add percentage and cap amount if percentage
  parentContainer.find('.number-percentage').on("blur", function() {
    let currentVal = parseFloat( $(this).val() );
    if (Number.isNaN(currentVal)) {
      $(this).val('');
    } else {
      $(this).val(currentVal + '%');
    }
  });

  // Require user to save before navigate to new section, if there is unsaved data
  $('a.list-title').click(function(e) {
    let valsChanged = $(this).closest('table').find('.response-card-body[data-val-changed=true]');
    let expanded = $(this).attr('aria-expanded');
    if ((valsChanged.length !== 0) && (expanded === 'true')) {
      removeNonChangedFields();
      $('#hiddenSaveResponse').click();
      enableNonChangedFields();    
    }
  })

  // Upload file
  // Set environment and account id for AWS path vars
  parentContainer.find('.response-document-filepicker').each(function(i, elem) {
    let fileInput     = $(elem);
    let filePicker    = $(this);
    let dropZone      = $(this).closest('.filepicker-container');
    let env           = fileInput.data("env");
    let primaryColor  = fileInput.data("primary-color");
    let accountUrl       = fileInput.data("account-url");
    var progressBar   = $("<div class='bar'></div>");
    var barContainer  = $("<div class='progress'></div>").append(progressBar);
    $(this).closest('.supporting-documentation').find('.file-upload-progress').append(barContainer);
    fileInput.fileupload({
      dropZone:         dropZone,
      fileInput:        fileInput,
      url:              fileInput.data('url'),
      type:             'POST',
      autoUpload:       true,
      formData:         fileInput.data('form-data'),
      paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%')
      },
      start: function (e) {
        progressBar.
          css('background', primaryColor).
          css('display', 'block').
          css('width', '0%').
          css("height", "25px");
      },
      done: function(e, data) {
        progressBar.text("");

        // extract key from response
        let key = $(data.jqXHR.responseXML).find("Key").text();
        let accountUrl = filePicker.attr('data-account-url');
        let answerId = filePicker.attr('data-answer-id');

        // Set card body to indicate value changed for reloading page
        let cardBody = $(this).closest('.card-body.response-card-body');
        cardBody.attr('data-val-changed', 'true');

        // Set spinner so user knows in progress
        cardBody.addClass('opaque');
        Spinner($(this).closest('.card'));

        // Upload file
        let url = "/" + accountUrl + '/data-collection/responses/' + responseId + '/documents';
        let formData = {}
        formData['file'] = {}
        formData['file']['key'] = key;
        formData['answer_id'] = answerId;

        // Set associated input field as filled
        fileInput.closest('.question-row').find('.answer-text').addClass('filled');
        fileInput.closest('.question-row').removeClass('incomplete');
        toggleNewData(fileInput);

        // Update local attribute
        return $.ajax({
          type: 'POST',
          url: url,
          dataType: "script",
          data: formData
        });

      },
      fail: function(e, data) {
        progressBar.
          css("background", "red").
          text("Failed");
      }
    });
  });


}