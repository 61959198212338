import { StreamActions } from "@hotwired/turbo"
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import GridNav from 'src/javascripts/components/questionnaires/GridNav';

export default function() {

	StreamActions.reload_datatables = function() {
	  let tableId = this.getAttribute("table_id");
	  $('#' + tableId).DataTable().ajax.reload();
	}

	StreamActions.toast = function() {
	  let title = this.getAttribute("title");
	  let text = this.getAttribute("text");
	  let length = parseInt(this.getAttribute("length"));
	  ToastCustom(title, text, length);
	}

	StreamActions.show_modal = function() {
	  let modalId = this.getAttribute("modal_id");

	  // Remove existing modal
	  $('#' + modalId).remove();

		// Adjust other modal if present
		let inModalView = false;
		if ($('body').hasClass('modal-open')) {
		  inModalView = true;
		  $('.modal').css('opacity', 0.2);
		  $('.sidebar-app').css('opacity', 0.05);
		  $('.navbar.nav-app').css('opacity', 0.05);
		  $('footer').css('opacity', 0.05);
		  $('.banner-container').css('opacity', 0.05);
		}

		// Append content 
	  $('body').append(this.templateContent);

		// Adjust other modal if in modal view
		if (inModalView === true) {
		  $('#' + modalId).on('hide.bs.modal', function(e) {
		    $('.modal').not($('#' + modalId)).css('opacity', 1.0);
		    $('.banner-container').css('opacity', 1.0);
		    $('.sidebar-app').css('opacity', 1.0);
		    $('.navbar.nav-app').css('opacity', 1);
		    $('footer').css('opacity', 1.0);
		  }).on('hidden.bs.modal', function(e) {  
		    $('body').addClass('modal-open');
		  });
		}

		// Enable client side validations in modal
		$('#' + modalId).on('shown.bs.modal', function() {
			$('#' + modalId).find('form').enableClientSideValidations();
		});

		// Re-init tooltips
		$('[data-toggle=tooltip]').tooltip();

		// Show the modal
	  $('#' + modalId).modal('show');
	}

	StreamActions.load_questionnaire_section = function() {

	  // Close other content if option passed
	  $('tr.collapse.show[data-auto-collapse=\'true\']').empty();

		// Append content
		let includedSectionId = this.getAttribute("included_section_id");
		let elTarget = $('.table.card-list[data-included-section-id=\'' + includedSectionId + '\']');

		// Append content 
	  elTarget.replaceWith(this.templateContent);

		// Navigating around the questionnaire grid layout
		GridNav('sectionList' + includedSectionId);

		// Re-init tooltips
		$('[data-toggle=tooltip]').tooltip();

	}


}



