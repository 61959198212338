import GridNav from 'src/javascripts/components/questionnaires/GridNav';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import Swal from 'sweetalert2';
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import ManualListTypeahead from 'src/javascripts/components/typeaheads/ManualListTypeahead';
import ManualListTypeaheadMultiple from 'src/javascripts/components/typeaheads/ManualListTypeaheadMultiple';
import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import 'summernote/dist/summernote-bs4.min.js'

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class QuestionnairesCollectionModulesShow {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');
    const responseId = $('.polling-indicator').attr('data-response-id');

    // Add border to navbar on scroll
    NavQuestionnaire();

    // Grid nav display
    GridNav();

    // Disable non-changed fields before submit, so only processing new data
    let removeNonChangedFields = function() {
      $('input.new-data').each(function() {
        if (this.value == "false") {
          $(this).closest('.form-group').find('input:not(:focus), select:not(:focus), textarea:not(:focus)').attr('disabled', true);
          $(this).closest('td').find('input:not(:focus), select:not(:focus), textarea:not(:focus)').attr('disabled', true);
        }
      })
    }

    // Enable non-changed fields; used after submit
    let enableNonChangedFields = function() {
      $('.data-collection-content input, .data-collection-content select, .data-collection-content textarea').attr('disabled', false).removeClass('temp');
    }  

    // Save and redirect
    let saveAndRedirect = function(href) {

      // Opacity on page
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('#submitResponseSpinnerContainer').removeClass('d-none');
      Spinner($('#submitResponseSpinnerContainer'));

      // Remove empty fields
      removeNonChangedFields();

      // Submit form
      $.when( $('#hiddenSaveResponse').click() )
        .then(data => Turbo.visit(href));

    }

    // Save before navigate to new element/section
    // Only save if there are changes to save
    $('a.list-title').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        saveAndRedirect(href);
      } else {
        Turbo.visit(href);
      }
    })

    // Save before navigating to next section
    $('a.visit-module-link').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        saveAndRedirect(href);        
      } else {
        Turbo.visit(href);
      }
    })

    // Save before navigating to modules home
    $('a.visit-modules-home-link').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        saveAndRedirect(href);        
      } else {
        Turbo.visit(href);
      }
    })

    // Copy an "as-of" date to all questions in a questionnaire
    $('.apply-date-to-all').click(function() {
      let dataAsOf = $(this).closest('.input-group').find('input').val();
      let params = {};
      params['data_as_of'] = dataAsOf;
      let url = "/" + accountUrl + "/data-collection/responses/" + responseId + "/answers/copy_as_of_date?" + $.param(params);

      // Opacity on page
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('#submitResponseSpinnerContainer').removeClass('d-none');
      Spinner($('#submitResponseSpinnerContainer'));

      // Submit
      $.ajax({
        type: "POST",
        dataType: "application/json",
        timeout: 3000,
        url: url,
        complete(result) { 
          let resultStatus = JSON.parse(result.responseText);
          let dataAsOf = resultStatus.data_as_of;
          $('.data-as-of').val(dataAsOf);
          $('#save_response_form .data-collection-content').css('opacity', 1)
          $('#submitResponseSpinnerContainer').addClass('d-none');
          $('#submitResponseSpinnerContainer').find('.spinner-container').remove();
        }
      })

    });

    // Copy an "as-of" date to all questions in a questionnaire
    $('.apply-provided-date-to-all').click(function() {
      let dataProvidedBy = $(this).closest('.input-group').find('.data-provided-by').val();
      let dataProvidedDate = $(this).closest('.input-group').find('.data-provided-date').val();
      let params = {};
      params['data_provided_by'] = dataProvidedBy;
      params['data_provided_date'] = dataProvidedDate;
      let url = "/" + accountUrl + "/data-collection/responses/" + responseId + "/answers/copy_as_of_date?" + $.param(params);

      // Opacity on page
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('#submitResponseSpinnerContainer').removeClass('d-none');
      Spinner($('#submitResponseSpinnerContainer'));

      // Submit
      $.ajax({
        type: "POST",
        dataType: "application/json",
        timeout: 3000,
        url: url,
        complete(result) { 
          let resultStatus = JSON.parse(result.responseText);
          let dataProvidedBy = resultStatus.data_provided_by;
          let dataProvidedDate = resultStatus.data_provided_date;
          $('.data-provided-by').val(dataProvidedBy);
          $('.data-provided-date').val(dataProvidedDate);
          $('#save_response_form .data-collection-content').css('opacity', 1)
          $('#submitResponseSpinnerContainer').addClass('d-none');
          $('#submitResponseSpinnerContainer').find('.spinner-container').remove();
        }
      })

    });


    // Timed save
    let timedSavePoll = (pollId) => {

      // Get polling indicator on page
      let savingPoll = $('.polling-indicator').attr("data-saving-poll");
      let status = $('.polling-indicator').attr('data-status');
      
      // Only continue if status indicator present (if should poll for recs is true)
      if ((savingPoll === pollId) && (status != "approved") && (status != "submitted")) {

        // Check if any values changed      
        let valChanged = $('.response-card-body[data-val-changed=\'true\']');

        // Remove non-changed fields
        removeNonChangedFields();

        // Submit form data
        let responseId = $('.polling-indicator').attr('data-response-id');
        let url = "/" + accountUrl + "/data-collection/responses/" + responseId + '/save';
        $.ajax({
          type: "PATCH",
          data: $("#save_response_form").serialize(),
          timeout: 10000,
          url: url,
          complete(result) { 
            setTimeout(function() { timedSavePoll(pollId) }, 10000);

            // Re-enable disabled fields
            enableNonChangedFields();

            // Set Api url to get data
            let accountUrl = $('body').attr('data-account-url');
            let params = {};
            let url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/poll.js?' + $.param(params);

            // Unhide polling spinner
            $('.polling-indicator').find('.spinner-border').removeClass('d-none');

            // Execute ajax request (using js erb template to render content so can control profile styling more easily)
            // Must specify '.js' otherwise processes as JSON
            $.ajax({
              type: "POST",
              timeout: 10000,
              url: url
            });

          }
        });

      }

    };

    // Poll server for status of mapped items, if generating message present
    let rand = 1 + Math.floor(Math.random() * 1000000);
    rand = rand.toString();
    $('.polling-indicator').attr("data-saving-poll", rand);
    setTimeout(function() { timedSavePoll(rand) }, 10000);

  }

}