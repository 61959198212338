import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class ManualListTypeaheadMultiple {

  constructor(el) { this.el = el; }

  render(id) {

    // Toggle new data indicator
    let toggleNewData = function(el) {
	    let row = el.closest('.question-row');
      let cardBody = el.closest('.response-card-body');
      let userId = cardBody.attr('data-user-id');
      let len = el.val.length;
      if (len > 0) {
        cardBody.attr('data-val-changed', 'true');
        cardBody.find('.user-id').attr('value', userId);
        cardBody.find('.new-data').attr('value', true);
      	row.find('.new-data').attr('value', true);
      } else {
        cardBody.attr('data-val-changed', 'false');
        cardBody.find('.user-id').attr('value', '');
        cardBody.find('.new-data').attr('value', false);
      	row.find('.new-data').attr('value', false);
      }
    }

	  let removeConditions = function(inputGroup) {
	    let conditions = inputGroup.attr('data-determining-conditions');
	    if (typeof conditions === 'undefined') {return}
	    conditions = JSON.parse(conditions);

	    // Add conditions from others tied to same included question
	    $('.input-group').filter(function() {
	      let c = $(this).attr('data-determining-conditions');
	      if (typeof c === 'undefined') {
	        return false
	      } else {
	        return c.includes('[' + conditions[0][0] + ',');
	      }
	    }).each(function() {
	      let ic = $(this).attr('data-determining-conditions');
	      let val = $(this).find('input.answer-text[type=\'hidden\']').val();
	      ic = JSON.parse(ic);
	      ic[0].push(val);
	      conditions.push(ic[0]);
	    })

	    if (typeof conditions !== 'undefined') {
	      let matchesCriteria = {};
	      $.each(conditions, function(i, arr) {
	        matchesCriteria[arr[0]] = 0;
	      });
	      $.each(conditions, function(i, arr) {
	        let dependentQuestion = arr[0];
	        let dependentTable = arr[1];
	        let displayVal = arr[2];
	        if (typeof arr[3] !== 'undefined') {
		        $.each(arr[3].split('|'), function(i,v) {
			        if ((typeof v !== 'undefined') && (v.length > 0) && (displayVal.indexOf(v) !== -1)) {
			          matchesCriteria[dependentQuestion]++;
			        }
		        });
		      }
	        if (typeof dependentQuestion !== 'undefined') {
	          let dependentRow = $('tr.question-row[data-included-question-id=' + dependentQuestion + ']');
	          if (matchesCriteria[dependentQuestion] > 0) {
	            dependentRow.removeClass('d-none');
	          } else {
	            dependentRow.addClass('d-none');
	          }
	        }
	        if (typeof dependentTable !== 'undefined') {
	          let dependentCard = $('div[data-included-table-id=' + dependentTable + ']');
	          let dependentRow = dependentCard.closest('tr.question-row');
	          if (matchesCriteria[dependentQuestion] > 0) {
	            dependentCard.removeClass('d-none');
	            dependentRow.removeClass('d-none');
	          } else {
	            dependentCard.addClass('d-none');
	            dependentRow.addClass('d-none');
	          }

	        }
	      })
	    }
	  }

	  // Remove option
	  let removeOption = function() {
		  $('.remove-multi-select').off().on('click', function() {
	  		let el = $(this);
	  		let ig = el.closest('.input-group');
	  		if (ig.length === 0) {ig = el.closest('td')}
		  	let removeVal = el.attr('data-text');
		  	let hidden = ig.find('input.answer-text');
		  	let row = el.closest('.question-row');
		  	if (hidden.val() !== null) {
		  		row.find('.new-data').attr('value', true);
		  		let currentVal = hidden.val();
		  		if (currentVal.indexOf(removeVal) !== -1) {
			  		let newVal = '';
			  		let arr = currentVal.split("|");
			  		let i = 0;
			  		while (i < arr.length) {
			  			let potentialVal = arr[i];
			  			if (potentialVal !== removeVal) {
			  				if (newVal === '') {
			  					newVal = potentialVal;
			  				} else {
			  					newVal += '|' + potentialVal;
			  				}
			  			}
			  			i++;
			  		}
			  		hidden.val(newVal);
			  	}
		  	}
		  	el.closest('.selected-opt').remove();

		  	// Update conditions
		    let hasDependencies = ig.attr('data-has-dependencies');
		    if (hasDependencies === "true") {
		      removeConditions(ig);
		    }    

		  })
		}

		// Run remove option initially
		removeOption();

		let listOptions = [];
		let i = 0;
		let optsList = $('#' + id).attr('data-list-options');
		if (typeof optsList === 'undefined') {return;}
		let dataOpts = JSON.parse(optsList);
    while (i < dataOpts.length) {
      listOptions.push(dataOpts[i]);
      i++;
    }

		var substringMatcher = function(strs) {
		  return function findMatches(q, cb) {

		    // an array that will be populated with substring matches
		    let matches = [];

		    // regex used to determine if a string contains the substring `q`
		    let substrRegex = new RegExp(q, 'i');

		    // iterate through the pool of strings and for any string that
		    // contains the substring `q`, add it to the `matches` array
		    $.each(strs, function(i, str) {
		      if (substrRegex.test(str)) {
		        matches.push(str);
		      }
		    });

		    cb(matches);
		  };
		};

		$('#' + id).typeahead({
		  hint: true,
		  highlight: true,
		  minLength: 0
		},
		{
		  name: 'list',
    	limit: 554,
		  source: substringMatcher(listOptions)
		});

	  // Save if conditions
	  $('#' + id).bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let ig = $(this).closest('.input-group');
	    let hasDependencies = ig.attr('data-has-dependencies');
	    if (hasDependencies === "true") {
	      removeConditions(ig, suggestion);
	    }
	  });

	  $('#' + id).bind('typeahead:beforeselect', function(ev, suggestion) {

	  	// Get closest container
	  	let ig = $(this).closest('td');
	  	if (ig.length === 0) {ig = $(this).closest('.input-group')}

	  	// Update hidden input value
	  	let hidden = ig.find('.answer-text');
	  	let selected = $(this).closest('.input-group').find('.multi-selected-container .selected-opt');
	  	if (selected.length === 0) {
	  		hidden.val(suggestion)
	  	} else {
	  		let updatedVal = suggestion;
	  		$.each(selected, function(i,k) {
	  			updatedVal += '|' + $(k).attr('data-text');
	  		});
	  		hidden.val(updatedVal);
	  	}

	  	// Append value to list of selected options
	  	let multiContainer = ig.find('.multi-selected-container');
	  	let selectedExists = multiContainer.find('.selected-opt[data-text=\'' + suggestion + '\']');
	  	if (selectedExists.length === 0) {
		  	let text = "<div class=\'selected-opt w-100 p-2 border-dashed-except-last d-flex align-items-center mr-2\' data-text=\'" + suggestion + "\' >"
		  	text += "<div class=\'mr-2\'>" + suggestion + "</div>";
		  	text += "<div class=\'ml-auto\'>";
		  	text += "<a class=\'clickable remove-multi-select\' data-text=\'" + suggestion + "\' data-toggle=\'tooltip\' data-title=\'Remove selected option\'>";
		  	text += "<i class=\'fad fa-trash\'></i>";
		  	text += "</a></div></div>"
		  	multiContainer.append(text)

		  	// Indicate that container has new data for saving
		  	toggleNewData(hidden);

		  	// Re-set remove function
		  	removeOption();

		  }

			$('#' + id).typeahead("destroy");
			$('#' + id).typeahead({
			  hint: true,
			  highlight: true,
			  minLength: 0
			},
			{
			  name: 'list',
	    	limit: 554,
			  source: substringMatcher(listOptions)
			});

	  });

	}
}